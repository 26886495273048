import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./term.scss";

function Term() {
  let history = useHistory();

  return (
    <div className="term">
      <Container className="mt-3">
        <Row>
          <Col lg="6" className="mb-4">
            <div className=" footer-img-wrapper ">
              <img
                className="img-fluid footer-logo "
                src={require("../../assets/images/logo.png").default}
                alt=""
                onClick={() => history.push("/")}
              />
            </div>
          </Col>
          <Col lg="6" className="mb-4">
            <div className="mbc social-logo h-100 d-flex justify-content-end align-items-center text-center">
              <a
                href="https://discord.gg/vwnDGbZn8m"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  style={{ height: "50px" }}
                  className="img-fluid cs ml-5 "
                  src={require("../../assets/images/Discord.png").default}
                  alt=""
                />
              </a>
              <a
                href="https://twitter.com/buddha_nft"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  style={{ height: "50px" }}
                  className="img-fluid cs ml-5 "
                  src={require("../../assets/images/Twitter.png").default}
                  alt=""
                />
              </a>
              <a
                href="https://opensea.io/LuckyBuddhaClub"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  style={{ height: "50px" }}
                  className="img-fluid cs ml-5 "
                  src={require("../../assets/images/Opensea.png").default}
                  alt=""
                />
              </a>
              <a
                href="https://www.instagram.com/luckybuddhaclub/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  style={{ height: "50px" }}
                  className="img-fluid cs ml-5 "
                  src={require("../../assets/images/Instagram.png").default}
                  alt=""
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
        <h1
          className="font-weight-bold"
          data-aos="fade-in"
          data-aos-duration="2000"
        >
          LuckyBuddha NFTs, Mandala NFTs, Website, Applications and Team
          (onwards "LuckyBuddha") Terms of Use{" "}
        </h1>
        <br />
        <h2>
          <strong>Terms and Conditions, Privacy Policy and Terms of Use</strong>
        </h2>
        <p>
          The LuckyBuddha website (https://www.LuckyBuddha.club) (onwards
          “Site”) allows users the opportunity to purchase unique cryptocurrency
          blockchain-tracked, non-fungible tokens (NFTs) that serve as digital
          collectibles. These Terms of Use (“Terms”) are a legally binding
          agreement between you and LuckyBuddha NFT and set forth the terms for
          your use of the Site (in any of its formats, including but not only
          limited to web, mobile, social, open markets) and the limited services
          made available through the Site as described herein ("features").
        </p>
        <p>
          This TERMS outline the rules and regulations for the use of the site.
          BY USING THE SITE OR SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS.
          IF YOU DO NOT AGREE WITH THESE TERMS, YOU MAY NOT USE THE SITE OR
          FEATURES.
        </p>
        <p>
          These Terms also apply all LuckyBuddha NFT and any of its affiliates
          (e.g., Mandala NFT, Augmtd.com, Augmtd.crypto) and associated mobile
          applications,
        </p>
        <p>
          You hereby explicitly grant LuckyBuddha the right, power, and
          authority to transmit your information to Third Party Vendors (“Third
          Party Vendors”) as reasonably necessary. All transactions made with
          LuckyBuddha are managed and confirmed on the blockchain, and are
          processed by third-party vendors. LuckyBuddha has no affiliation with
          any of those Third Party Vendors, and will only use any information
          that may be publicly available on the blockchain. You agree that
          LuckyBuddha is not responsible for the availability of any external
          websites, mobile applications or resources accessible from those Third
          Party Vendors and LuckyBuddha is not responsible or liable for any
          content, advertising, products, services, or other materials available
          Third Party websites, mobile applications or any other platform.
        </p>
        <p>
          Although you might be enabled to “Mint” (or Buy) on the website or
          Third Party, in the pre-sale each buyer will receive a random
          LuckyBuddha NFT from the collection that will be revealed after the
          purchase of the total NFTs available for presale, sale or resale. In
          the event of resales or transfers of our NFTs, we do not monitor or
          control any marketplace purchases or sales, or any other activity
          beyond that on the Features.
        </p>
        <p>
          You acknowledge and agree that all transactions effectuated through
          LuckyBuddha are publicly visible on the blockchain.
        </p>
        <p>
          Any purchase is final and non-refundable. We keep the right to use one
          or more cryptocurrencies that we may accept in the future, limit the
          fiat dollar amount (or in fact any other currencies) and number of any
          transactions.
        </p>
        <p>
          Users are sole responsible for payment of all taxes (national, federal
          ,state, local or other jurisdictions) including, without limitation,
          any income, sales, use, value-added (VAT), goods and services and
          other taxes and duties associated with your exchange with LuckyBuddha,
          LuckyBuddha Affiliates and Third Party Providers. And buyers are also
          solely responsible for any tax reporting for transactions in which you
          may be a seller of NFTs, including but not limited to LuckyBuddha or
          Mandala NFTs.
        </p>
        <h3>
          <strong>License Agreement</strong>
        </h3>
        <p>
          By using our site you are accepting our Terms, you also agree that you
          will not and will not permit a third party without LuckyBuddha written
          consent in any of the following actions or omissions: a. Attempt to
          mint, tokenize, or create an additional token representing our Artwork
          on any platform; b. Falsify, represent, or give authorship of the
          artwork; c. Change the characters associated with your NFT; d. Utilize
          the art pieces in connection with any offensive, obsene, malicious,
          harmful materials or media; e. Use or incorporate the NFTs in movies,
          videos, video games, or any other forms of media for a commercial
          purpose; f. Commercialize merchandising that includes, contains, or
          consists of the NFTs and LuckyBuddha properties; g. Copyright or
          attempt to acquire additional intellectual property rights to the art
          pieces and its related properties, including LuckyBuddha NFT
          trademark, logo or related subsidiaries. The Holder agrees not to use,
          portray, advertise, market or distribute any LuckyBuddha in any
          project or work that implies hate speech, racism, pornography, or any
          other illegal or unlawful content.
        </p>

        <h3>
          <strong>Ownership and Transfers</strong>
        </h3>
        <p>
          You accept that LuckyBuddha NFT owns all copyrights, rights, titles
          and all intellectual property rights. Any rights that you may have are
          limited to those expressly in the current statement.
        </p>
        <p>
          Unless otherwise stated, LuckyBuddha website (LuckyBuddha.club) and/or
          its licensors own the intellectual property rights for all LuckyBuddha
          NFT materials. That includees 8,888 NFTs, trademark, logos, web
          designs, codes and all artworkk hosted in the blockchain marketplace
          chosen by the owners of the project. By minting a Lucky Buddha NFT or
          Mandala NFT from our smart contract or in the secondary market (e.g.
          OpenSea), or other legitimate methods, the buyer receives full and
          complete ownership, which includes right to use under our License
          Agreement clause. Upon legitimate sale or transfer of the NFT in the
          marketplace, ownership and rights are immediately transferred to the
          new buyer. No refunds applies upon completion of legitimate purchase
          of any NFT.
        </p>

        <h3>
          <strong>Privacy Policy, Cookies, and Beacons</strong>
        </h3>
        <p>
          LuckyBuddha websites, online services, and interactive applications,
          may use “cookies” and other technologies such as beacons. These
          cookies are used to understand how visitors interact with our websites
          and online services, to help us assess the effectiveness of our
          experiences. It is also use to remember choices you make while
          browsing to provide a more personalized navigation for people. If you
          want to disable cookies, you can do it on your privacy settings in the
          browser that
        </p>
        <p>
          LuckyBuddha considers people privacy paramount priotity for our
          visitors and buyers. Only necessary data available will be collected
          from consumers to offer a better experience.
        </p>

        <p>
          Eventually, LuckyBuddha will require and ask for your personal
          information (name, company, email, telephone number, mailing address)
          to ensure personalized services and provide a good service. We will
          use your information to make improvements to our website,
          applications, analyze users behavior, create new products,
          functionalities, services, interact directly with you. In all
          circumstances, LuckyBuddha will dedicate its best efforts to find and
          prevent any fraudulent action.
        </p>
        <p>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in Lucky Buddha NFT | Original. This policy is
          not applicable to any information collected offline or via channels
          other than this website, Search engines, News organizations,
          Influencers, Affilated Websites, Non-Profit organizatoins,
          Fundraisers, Government agencies; among other institutions.
        </p>
        <p>
          Any of the stated organizations can link to our pages as long as: a.
          it doesn’t not imply sponsorhip or endorsement of any kind, b. fits
          with the content and context of LuckyBuddha NFT.
        </p>
        <p>
          We reserve the right to solicite the removal of any link to any of our
          properties if we find that: a. it is unfavorable to our business, b.
          the source link has negative reputation, c. there is no benefit for
          LuckyBuddha.
        </p>
        <p>
          If you are one of the organizations interested in linking to our
          website, you must inform us by contacting via email, twitter or
          discord . Please include your name, your organization name, contact
          information as well as the URL of your site, a list of any URLs from
          which you intend to link to our Website, and a list of the URLs on our
          site to which you would like to link. Wait for our written response.
        </p>
        <p>
          At any time, when using LuckyBuddha NFT properties as well as any of
          its subsidiaries, you must be allowed by our trademark license
          agreement.
        </p>
        <p>
          By visiting our website, applications log files with information gets
          collected by hosting services and analytical tools. The log
          information is in its majority the internet provider address, type of
          browser, protocol used, geography, pages visited, clicks and time
          spent on the site. None of the information collected is durable or
          identifiable, meaning personal or private information about the user.
        </p>
        <p>
          Adservers, Adnetworks, Demand Side Platforms, Syndicated sites are
          third parties that automatically receive information from LuckyBuddha
          with the objective of measuring and report advertisement results.
        </p>
        <h3>
          <strong>
            DO NOT SELL MY PERSONAL INFORMATION: CCPA, GDPR, PIPL.
          </strong>
        </h3>
        <p>
          Under the regulations mentioned above, all people are entitled to: a.
          Request the type of data and specific fields of information a business
          has collected from them, b. Request to delete any piece of information
          or data, c. Request no to sell their personal data, if the business
          sells personal data, d. Request to rectify their information or data
          registered, e. Oppose or limit any processing of their personal data,
          f. Request to transfer their personal data to another organization,
          institution
        </p>
        <p>
          In any of the above mentioned events, LuckyBuddha has 30 working days
          to respond to you. To exercise these rights, you can contact us via
          email, twitter or discord.
        </p>
        <h3>
          <strong>Warranties</strong>
        </h3>
        <p>
          Everything you do on our sites and related services, its at your sole
          discretion, risk and voluntary. All services are provided under
          applicable laws. There is no warranty that the digital properties
          (website, mobile, applications, etc) and its functionalities will be
          free of errors, bugs, defects. There is no warranty that servers are
          free of virus or harmful bugs or damaging components. Limitations of
          Liability for Gas, Failed Transactions, Smart Contract Bugs.
          LuckyBuddha cannot warranty by no means any continuous availability of
          services and features, transactions security, neither completion of
          processes and stability of networks. You may want to stop the use of
          our digital properties at any time if you do not agree to this terms.
        </p>
        <p>
          Under no circumstances will the operation of all or any portion of the
          Site or Services by LuckyBuddha be deemed to create a relationship
          that includes the provision or tendering of investment advice.
        </p>
        <p>
          Although Lucky Buddha NFT has released a whitepaper and roadmap for
          the future, all planning will be dependent on the organic evolution of
          the project and funds available to the community. There are no
          warranties that LuckyBuddha will be tight to a fixed timeline. You
          should not purchase LuckyBuddha under any benefit or profit
          assumptions or speculation. When minting or buying LuckyBuddha NFTs
          you agree that you it is your sole decision and you are not relying on
          any future commitments made by Lucky Buddha NFT for its collectibles,
          investments or donations. LuckyBuddha NFT and its team make no promise
          or guarantee that the project will retain monetary value of any kind
          (ie, Fiat, Cryptocurrency, others).
        </p>
        <h3>
          <strong>Indemnifications</strong>
        </h3>
        <p>
          You will indemnify, defend and hold LuckyBuddha NFT and subsidiaries
          harmless together with any of their respective officers, agents,
          directors, representatives, contractors, and employees, from any and
          all claims, losses, liabilities, damages, penalties, fines, suits,
          demands, actions, judgements, expenses (including attorneys) arising
          from your breach or alleged breach of this Terms. LuckyBuddha NFT
          reserves the rigth to have its own defense as alternative.
        </p>
        <p>
          Your rights and remedies in the event of any breach of this Agreement
          are strictly limited to the right, if any, to recover damages and
          acknowledge them as adequate. It will not entitle you to pursue any
          equitable relief or compensation.
        </p>
        <p>
          Limitation of Legal Remedies. If there is a Dispute that remains
          unresolved after mediation, INSTEAD OF SUING IN COURT, you and
          LuckyBuddha agree to the fullest extend permitted by Law to pursue
          binding arbitration based on arbitration procedures, rules and
          regulations, ON INDIVIDUAL BASIS, without class relief. The ONLY
          EXCEPTION will be those related to disputes pertaining to LuckyBuddha
          copyrights, property rights and statutory claims that are not
          arbitrable. This is intended to be broadly interpreted, and it aims to
          include contracts, clauses, frauds, misinterpretation and any other
          legal claim. The arbitrator's decision and award will be final and
          binding, with some exceptions applied by law, and judgment on the
          award may be entered in any court with jurisdiction.
        </p>

        <h3>
          <strong>Limited liabilities</strong>
        </h3>
        <p>
          In no case or event LuckyBuddha or its subsidiaries will be liable to
          you for any direct or indirect, incidental, or consequential damages
          (including loss of revenue or profits) with respect to These Terms,
          whether such liability arises from any claim based upon contract. You
          are advised of the possibility of losses, damages or issues related to
          interacting with our properties and third party vendors. Any potential
          LuckyBuddha's liability shall by no circumstances exceed the net
          revenues actually received by LuckyBuddha as consequence of any
          transaction in which you purchased or sold your LuckyBuddha NFT. The
          foregoing limitation of liability shall only apply to the extent
          permitted by applicable law. LuckyBuddha will not be hold accountable
          as a result of any bugs, failure, removal, disruption of service or
          termination for its own servers, third party vendors and any other NFT
          platforms.
        </p>
        <p>
          We shall not be hold responsible for any content that appears on the
          Website or applications. LuckyBuddha or its team will no be held
          responsible for high Gas fees, Failed Transactions, Smart Contract
          Bugs. Participate in minting with the assumption that you are hold
          accountable for any losses incurred as a consequence of minting an NFT
          costs, fails and losses.
        </p>
        <p>
          LuckyBuddha we will not be liable for any loss or damage of any nature
          for all content and experiences offered free of charge.
        </p>
        <p>
          Class Action Waiver. You agree to waive any class action status, and
          any legal dispute to LuckyBuddha. You may only choose to bring can
          only be done on an individual basis. You and LuckyBuddha agree that
          claims will only be brought in an individual capabity and not in class
          action or representative proceeding. All arbitrations with LuckyBuddha
          must be conducted on an individual (not a class-wide) basis, and
          arbitrators will have no authority to provide class-wide relief. You
          acknowledge and agree that these Terms and Conditions prohibit you
          from commencing any legal proceedings as a representative of others,
          as a class representative, class member or acting as a private
          attorney general, or joining or consolidating Claims with claims or
          proceedings brought by any other person.
        </p>
        <p>
          If there is a Dispute that remains unresolved after mediation, INSTEAD
          OF SUING IN COURT, YOU AND TIME EACH AGREE TO THE FULLEST EXTENT
          PERMITTED BY LAW TO ARBITRATE DISPUTES THROUGH BINDING ARBITRATION
          PURSUANT TO THE JAMS ARBITRATION RULES AND PROCEDURES, ON AN
          INDIVIDUAL BASIS, WITHOUT CLASS RELIEF, EXCEPT FOR DISPUTES PERTAINING
          TO TIME’S INTELLECTUAL PROPERTY RIGHTS AND STATUTORY CLAIMS THAT
          PURSUANT TO LAW ARE NOT ARBITRABLE. This agreement to arbitrate is
          intended to be broadly interpreted. It includes claims based in
          contract, tort, statute, fraud, misrepresentation or any other legal
          theory (“Claim(s)”). The arbitrator's decision and award are final and
          binding, with some exceptions under the Federal Arbitration Act, 9
          U.S.C. 1, et seq., and judgment on the award may be entered in any
          court with jurisdiction.
        </p>
        <p>
          A party (resident or non-resident) who intends to seek arbitration
          must first send to the other a written Notice of Dispute describing
          the nature and basis of the Claim and setting forth the specific
          relief sought. All Notices to TIME must be sent to the following: a.
          email, b. twitter, and c.discord. Our notice to you will be sent to
          you based on the most recent contact information provided by you to
          us. Upon receipt of such Notice, the receiving party will have a
          60-day period in which it may satisfy the Claim against it by fully
          curing the Claim. After the expiration of such 60-day cure period,
          unless otherwise agreed by you and LuckyBuddha, the arbitration will
          be governed and conducted by Argentina Law and Jurisdiction before a
          single arbitrator with substantial experience in the internet industry
          and shall follow substantive law in adjudicating the Dispute. The
          arbitration of any claim will be conducted in the State of Buenos
          Aires, and for any non-frivolous claim that does not exceed $5,000.00,
          you shall have the choice as to whether the hearing is conducted in
          person or by telephone. Each party will pay the fees and costs of its
          own counsel, experts and witnesses.
        </p>
        <p>
          Jury Trial Waiver. YOU AND TIME EACH VOLUNTARILY WAIVE THE RIGHT TO A
          TRIAL BY JURY IN RESOLVING ANY DISPUTE BETWEEN US ARISING OUT OF THESE
          TERMS OR THE SITES OR SERVICES.
        </p>

        <p>
          Only disputes or actions pertaining to LuckyBuddha intellectual
          property rights, or statutory claims that pursuant to law are not
          arbitrable, are exempt from arbitration.
        </p>
        <p>
          This arbitration provision shall survive termination of these Terms.
        </p>
        <p>
          If any provision of this Section is declared or found by a court of
          competent jurisdiction or arbitrator to be unlawful, unenforceable or
          void, such provision will be ineffective only to the extent that it is
          found unlawful, unenforceable or void, and the remainder of the
          provision and rest of provisions will remain fully enforceable, except
          that if the Class Action Waiver for any Claim cannot be enforced, then
          the provision to arbitrate will not apply.
        </p>

        <h3>
          <strong>Legal Jurisdiction. </strong>
        </h3>
        <p>
          Regardless of your country of residence or establishment (ie, inside
          or outside Argentina) or whether you are an individual or business
          entity, these Terms will be governed by and interpreted in accordance
          with Argentine law. This choice of law does not impact your rights as
          a consumer according to the consumer protection laws of your country
          of residence. The application of the United Nations Convention on
          Contracts for the International Sale of Goods (CISG) is excluded.
        </p>
        <h3>
          <strong>Childs Policy. </strong>
        </h3>
        <p>
          We do not advertise to minors or kids. By accepting these terms,
          LuckyBuddha buyers, audiences, and users are above 18 years old, or
          legal age based on their jurisdiction. Anyone below 18 years old
          participates under supervision of an adult.
        </p>

        <h3>
          <strong>iFraming</strong>
        </h3>
        <p>
          It is prohibited to create frames to use your website or experiences
          as container of ours. It is not allowed to modify our visual identity
          or appereance.
        </p>
        <h3>
          <strong>Scope of policy</strong>
        </h3>
        <p>
          We advise you to always consult the Third Party Terms and Conditions,
          Privacy Policies, and Terms of Use. LuckyBuddha Terms and Conditions,
          Privacy Policy and Terms of Use do not apply to Third Parties.
        </p>
        <p>
          Miscellaneous. This Agreement constitutes the complete understanding
          and agreement of you and LuckyBuddha to every article and it
          supersedes all pior written or oral agreements between you and
          LuckyBuddha. The language of any clause or term of this Agreement will
          not be construed for or against the drafter and writer. No right or
          term of this Agreement will be deemed waived, and no breach of this
          Agreement excused, unless the waiver or consent is in writing and
          signed by you and LuckyBuddha. Both parties must agree in written and
          signed.
        </p>
        <p>
          LuckyBuddha NFT is a blockchain-based generative project that
          consisting of 8,888 unique Lucky Buddha characters and Mandalas, some
          with music displaying randomized traits. These terms and conditions
          outline the rules and regulations for the use of LuckyBuddha.club ,
          located at https://www.LuckyBuddha.club Any questions can be sent over
          to the team via{" "}
          <a
            href="https://twitter.com/buddha_nft"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>{" "}
          or{" "}
          <a
            href="https://discord.gg/vwnDGbZn8m"
            target="_blank"
            rel="noreferrer"
          >
            Discord
          </a>
          .
        </p>
      </Container>
    </div>
  );
}

export default Term;
