import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import Term from "./pages/Term/Term";
AOS.init();

function App() {
  return (
    <>
      <Router>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/terms">
          <Term />
        </Route>
      </Router>
    </>
  );
}

export default App;
