import React, { useEffect, useState } from "react";
import "./home.scss";
import { Container, Nav, Navbar, Row, Col } from "react-bootstrap";
import AboutImg from "../../assets/images/LuckyBuddhaExperience.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Carousel from "../../components/Carousel/Carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Web3 from "web3";
import { Link } from "react-router-dom";
import abi from "./abi.json";

function Home() {
  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(null);
  const [cost, setCost] = useState(8);

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);
    const ins = new web3.eth.Contract(
      abi,
      "0xE9Acb599817D50B11b7589E3cF8473C66d661792"
    );

    setInstance(ins);
    const getTotalMinted = async () => {
      var tokenCount = await ins.methods.totalSupply().call();
      console.log(tokenCount);
      var n = await ins.methods.cost().call();
      var price = web3.utils.fromWei(n, "ether");
      setCost(price);
      setTotalMinted(tokenCount);
    };
    getTotalMinted();
  }, []);

  function connect() {
    web3.eth.requestAccounts().then((res) => {
      setAdd(res[0]);
      web3.eth.defaultAccount = res[0];
    });
  }

  const mint = async () => {
    if (add) {
      if (instance) {
        console.log(
          await instance.methods.mint(mintNumber).send({
            from: add,
            value: (
              (await instance.methods.cost().call()) * mintNumber
            ).toString(),
          })
        );
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const plus = () => {
    if (mintNumber < 8) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  return (
    <div>
      <div className="showcase">
        <div className="pt-2">
          <Container fluid>
            <Navbar className="cus-nav" variant="dark" expand="lg">
              <Navbar.Brand
                href="#top"
                className="font-weight-bold text-uppercase"
              >
                <img
                  className="img-fluid logo mr-3"
                  src={require("../../assets/images/logo.png").default}
                  alt=""
                />
              </Navbar.Brand>
              <div className="">
                <a
                  href="https://www.instagram.com/luckybuddhaclub/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="si"
                    src={require("../../assets/images/Instagram.png").default}
                    alt=""
                  />
                </a>
                <a
                  href="https://twitter.com/buddha_nft"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="si"
                    src={require("../../assets/images/Twitter.png").default}
                    alt=""
                  />
                </a>
                <a
                  href="https://discord.gg/vwnDGbZn8m"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="si"
                    src={require("../../assets/images/Discord.png").default}
                    alt=""
                  />
                </a>
                <a
                  href="https://opensea.io/collection/luckybuddhaclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="si"
                    src={require("../../assets/images/Opensea.png").default}
                    alt=""
                  />
                </a>
              </div>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Nav.Link href="#about" className="link-style linkh">
                    About
                  </Nav.Link>
                  <Nav.Link href="#gallery" className="link-style linkh">
                    Gallery{" "}
                  </Nav.Link>
                  <Nav.Link href="#roadmap" className="link-style linkh">
                    Roadmap
                  </Nav.Link>
                  <Nav.Link href="#team" className="link-style linkh">
                    Team
                  </Nav.Link>
                  <Nav.Link href="#faq" className="link-style linkh">
                    FAQ{" "}
                  </Nav.Link>
                  <Nav.Link
                    onClick={connect}
                    className="text-center link-style btn cusbtn"
                  >
                    <span className="text-center">
                      {add ? add.substring(0, 15) : "Connect Wallet"}
                    </span>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>
        <div className="showcase-content">
          <Container
            className=" countd d-flex align-items-center justify-content-center h-75"
            fluid
          >
            <Row className="">
              <Col
                className=" d-flex justify-content-center align-items-end "
                lg="12"
              >
                <div className="mintbox p-3">
                  <div className="text-center">
                    <button onClick={connect} className="cus-btn w-100">
                      <span className="text-center">
                        {add ? add.substring(0, 13) : "Connect Wallet"}
                      </span>
                    </button>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <div className="mx-3">
                      {/* <div onClick={connect} className="btn cusbtn">
                        <span className="text-center">
                          {add ? add.substring(0, 15) : "Connect Wallet"}
                        </span>
                      </div> */}
                      <img
                        onClick={minus}
                        className="plus-img"
                        src={require("../../assets/images/minus.png").default}
                        alt=""
                      />
                    </div>
                    <div className="display-number d-flex justify-content-center align-items-center">
                      <span className="number">{mintNumber}</span>
                    </div>
                    <div className="mx-3">
                      <img
                        onClick={plus}
                        className="plus-img"
                        src={require("../../assets/images/plus.png").default}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="text-center mt-5">
                    <button onClick={mint} className="cus-btn w-100">
                      Mint
                    </button>
                  </div>
                  <div className="text-center mt-3">
                    <h3 className="font-weight-bold">
                      <span className="text-white">{totalMinted} </span> / 8888
                    </h3>
                  </div>

                  <div className="text-center mt-4">
                    <h3 className="font-weight-bold">{cost} MATIC Each</h3>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container
            className="h-25 mbcon d-flex align-items-end justify-content-center"
            fluid
          >
            <Row className="text-center w-100">
              <Col className="rmt-3" lg="4">
                <div className="">
                  <h1 className="tit">8,888</h1>
                  <h5 className="subtit">8,888 unique designed LuckyBuddhas</h5>
                </div>
              </Col>
              <Col className="rmt-3" lg="4">
                <div className="">
                  <h1 className="tit">{cost} Polygon </h1>
                  <h5 className="subtit">
                    Fair Launch, fair distribution: Lucky Buddhas cost starts at
                    {cost} Polygon
                  </h5>
                </div>
              </Col>
              <Col className="rmt-3" lg="4">
                <div className="">
                  <img
                    className="img-fluid showlogo mb-2 pt-2"
                    src={require("../../assets/images/ownericon.png").default}
                    alt=""
                  />
                  <h5 className="subtit">
                    Ownership, copyright and commercial usage rights given to
                    the holder over their NFT
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div id="about" className="about">
        <Container className=" h-100">
          <Row className=" h-100">
            <Col
              lg="6"
              className="d-flex align-items-center justify-content-center rmt-3 "
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="aboutimg">
                <img className="img-fluid about-img" src={AboutImg} alt="" />
              </div>
            </Col>
            <Col
              lg="6"
              className="d-flex align-items-center rmt"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="about-text">
                <h2 className="cusfont title text-uppercase">
                  Somewhere in the metaverse
                </h2>
                <p className="mt-1 subtitle">
                  In the exciting and colorful planet called Nirvana, somewhere
                  on the metaverse, a venerated and veteran monk under the name
                  of "Lucky Buddha" carries his "cloth sack" wandering from
                  planet to planet. Joyfull, humorous, and eccentric, Lucky
                  Buddha lives in a different time and space. He wanders in the
                  metaverse and mirrors its image based on people's inner soul.
                  Whatever your desire, Lucky Buddha will know and reflect it in
                  his character. If you are an artist, it will become an artist,
                  if you are a dragon, then it will turn into a dragon. Lucky
                  Buddha believes that the path to happiness, joy and peace of
                  mind can be reached by giving others. So the legend says that
                  Lucky Buddha has the power to take the best out of people and
                  encourage them to reach enlightment. The best way to find your
                  Lucky Buddha is to let your Lucky Buddha find you. It will
                  help you reach enlightment by unlocking his Mandalas.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="gallery" className="mx-5">
        <Container className="" fluid>
          <Row>
            <Col>
              <Carousel />
            </Col>
          </Row>
        </Container>
      </div>

      <div id="" className="dracoexp">
        <Container className="">
          <div className="text-center">
            <h1
              data-aos="fade-up"
              data-aos-duration="2000"
              className="title cusfont"
            >
              Lucky Buddha Experience
            </h1>
          </div>
          <Row className="con-1 h-100">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className=""
            >
              <div className="">
                <div className="">
                  <div className="">
                    <img
                      className="img-fluid expicon"
                      src={
                        require("../../assets/images/icons/IconExclusitivity.png")
                          .default
                      }
                      alt=""
                    />
                    <span className="cusfont ml-3 exp-title">
                      UNIQUE RARITY
                    </span>
                  </div>
                  <div className="mt-2">
                    <span className="exp-subtitle">
                      Lucky Buddha and Mandala NFTs | Original offers an
                      exclusive 8,888 art piece collection that includes
                      extremely rare elements and special graphics for a grand
                      total of unique traits.
                    </span>
                  </div>
                </div>
                <div className="con-1">
                  <div className="">
                    <img
                      className="img-fluid expicon"
                      src={
                        require("../../assets/images/icons/IconRewards.png")
                          .default
                      }
                      alt=""
                    />
                    <span className="cusfont ml-3 exp-title">REWARDS</span>
                  </div>
                  <div className="mt-2">
                    <span className="exp-subtitle">
                      As the project continues to grow, so too does the number
                      of exclusive access and rewards given to our community. We
                      will constantly offer prizes in multiple forms, such as
                      airdrops, sneak-peaks, merchandise, giveaway and much
                      more. Our goal is to constantly give back to our
                      supportive community through this evolution.
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className="rmt-3 d-flex align-items-center justify-content-center"
            >
              <div className="">
                <img
                  className="img-fluid"
                  src={require("../../assets/images/coin.gif").default}
                  alt=""
                />
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className="rmt-3"
            >
              <div className="">
                <div className="">
                  <div className="">
                    <img
                      className="img-fluid expicon"
                      src={
                        require("../../assets/images/icons/IconTransparent.png")
                          .default
                      }
                      alt=""
                    />
                    <span className="cusfont ml-3 exp-title">TRANSPARENCY</span>
                  </div>
                  <div className="mt-2">
                    <span className="exp-subtitle">
                      We believe in complete ownership and fair distribution
                      within our community. All the royalties collected will be
                      invested in the community and a portion donated to
                      institutions of its choice!
                    </span>
                  </div>
                </div>
                <div className="con-1">
                  <div className="">
                    <img
                      className="img-fluid expicon"
                      src={
                        require("../../assets/images/icons/IconCommunity.png")
                          .default
                      }
                      alt=""
                    />
                    <span className="cusfont ml-3 exp-title">COMMUNITY</span>
                  </div>
                  <div className="mt-2">
                    <span className="exp-subtitle">
                      Become part of an amazing community that encourages love
                      for one another and the planet. First 888 members will
                      become Siddhartas. As our member base expands our goal is
                      to give each and every one of our collectors a more
                      rewarding experience.
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="4"></Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className="rmt-5"
            >
              <div className="">
                <div className="">
                  <div className="">
                    <img
                      className="img-fluid expicon"
                      src={
                        require("../../assets/images/icons/IconGiving.png")
                          .default
                      }
                      alt=""
                    />
                    <span className="cusfont ml-3 exp-title">
                      ABUNDANCE IN GIVING
                    </span>
                  </div>
                  <div className="mt-2">
                    <span className="exp-subtitle">
                      As collections release, we envisioned and planned a
                      strategic evolution of the project to use its funds to
                      develop a game, a marketplace and a coin to help people
                      that need more luck in life through donations to
                      institutions of choice!
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="roadmap" className="roadmap">
        <div className="">
          <h1 className="cusfont text-uppercase text-center font-weight-bold">
            Roadmap
          </h1>
        </div>
        <div className="text-center mt-5">
          <img
            data-aos="fade-up"
            data-aos-duration="2000"
            className="img-fluid road"
            src={require("../../assets/images/roadmap.png").default}
            alt=""
          />
        </div>

        <Container className=" mt-5">
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3"
              lg="3"
            >
              <div className="">
                <img
                  className="img-fluid"
                  src={require("../../assets/images/5.png").default}
                  alt=""
                />
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3 d-flex align-items-center"
              lg="9"
            >
              <div className="">
                <span
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="number-text"
                >
                  10% 888 early adopters get Nirvanas roles in Discord, have
                  unique access to future rewards and tweek LuckyBuddha traits.
                  Official certificates will be given to holders. Every NFT will
                  contribute to a fund for scholarships of the community choice
                  &amp; Lucky Buddha Game.
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3"
              lg="3"
            >
              <div className="">
                <img
                  className="img-fluid"
                  src={require("../../assets/images/4.png").default}
                  alt=""
                />
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3 d-flex align-items-center"
              lg="9"
            >
              <div className="">
                <span
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="number-text"
                >
                  25% We will work to get the collection on{" "}
                  <a
                    href="http://rarity.tools"
                    target="_blank"
                    rel="noreferrer"
                  >
                    rarity.tools
                  </a>
                  &nbsp; and partner{" "}
                  <a
                    href="https://raritysniper.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    raritysniper
                  </a>{" "}
                  to get rarity bot in the discord server, so you can find it
                  easy to join upcoming events. We hope to have sufficient
                  funding funds to host an event from the beginning of 2022.
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3"
              lg="3"
            >
              <div className="">
                <img
                  className="img-fluid"
                  src={require("../../assets/images/3.png").default}
                  alt=""
                />
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3 d-flex align-items-center"
              lg="9"
            >
              <div className="">
                <span
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="number-text"
                >
                  50% LuckyBuddha NFTs giveaways will be used for People or
                  Institution selected by the community (Mandalas). Polygon will
                  be also rewarded to LuckyBuddha Holders. More treats will be
                  created based on the community votes and desires.
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3"
              lg="3"
            >
              <div className="">
                <img
                  className="img-fluid"
                  src={require("../../assets/images/1.png").default}
                  alt=""
                />
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3 d-flex align-items-center"
              lg="9"
            >
              <div className="">
                <span
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="number-text"
                >
                  75% An amount agreed with LuckyBuddha Holders (Nirvanas) will
                  be donated to one institution running research for the planet.
                  All voted by the community through transparent polls in social
                  media. Funds available will be used to create and launch the
                  LuckyBuddha Game Upgrades.
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3"
              lg="3"
            >
              <div className="">
                <img
                  className="img-fluid"
                  src={require("../../assets/images/2.png").default}
                  alt=""
                />
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              className="rmt-3 d-flex align-items-center"
              lg="9"
            >
              <div className="">
                <span
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="number-text"
                >
                  100% LuckyBuddhas largest holders will win a unique virtual
                  &amp; real art piece of the collection. Random holders will
                  get a unique Tibetan counter. 8% of the income generated will
                  flow back to community wallets.
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="team" className="team">
        <Container className=" h-100">
          <div className="text-center">
            <h1 className="cusfont text-uppercase text-center font-weight-bold">
              Meet The Team
            </h1>
          </div>
          <Row className="h-100 mt-h">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="6"
              className="d-flex justify-content-center"
            >
              <div className="">
                <div className="">
                  <img
                    className="img-fluid team-img"
                    src={require("../../assets/images/team1.png").default}
                    alt=""
                  />
                </div>
                <div className="mt-4">
                  <h2 className="text-center">augmtd.crypto</h2>
                </div>
                <div className="mt-3 d-flex justify-content-center">
                  <a
                    href=" https://www.instagram.com/sontustoces/"
                    target="_blank"
                    rel="noreferrer"
                    className="px-3"
                  >
                    <img
                      className="img-fluid social-icon"
                      src={require("../../assets/images/Instagram.png").default}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="6"
              className="d-flex justify-content-center rmt"
            >
              <div className="">
                <div className="">
                  <img
                    className="img-fluid team-img"
                    src={require("../../assets/images/team2.png").default}
                    alt=""
                  />
                </div>
                <div className="mt-4">
                  <h2 className="text-center">neural.crypto</h2>
                </div>
                <div className="mt-3 d-flex justify-content-center">
                  <a
                    href="https://www.instagram.com/mbosaz/"
                    target="_blank"
                    rel="noreferrer"
                    className="px-3"
                  >
                    <img
                      className="img-fluid social-icon"
                      src={require("../../assets/images/Instagram.png").default}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://discord.gg/QtfU5CtP3E "
                    target="_blank"
                    rel="noreferrer"
                    className="px-2"
                  >
                    <img
                      className="img-fluid social-icon"
                      src={require("../../assets/images/Discord.png").default}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="faq" className="faq text-center">
        <Container>
          <Row>
            <Col lg="12">
              <Accordion>
                <AccordionItem data-aos="fade-up" data-aos-duration="2000">
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      What’s the launch date for LuckyBuddha NFT?{" "}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Pre-sale launch begins on December 13th, 2021 and Public
                      launch begins on Jan 1st, 2022
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem data-aos="fade-up" data-aos-duration="2000">
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      What is Lucky Buddha NFT?{" "}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Lucky Buddha is an exclusive 8888 art piece collection of
                      meta-buddhas and mandalas that includes extremely rare
                      elements and special graphics for a grand total of over
                      150 unique traits.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem data-aos="fade-up" data-aos-duration="2000">
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      How do I get a Lucky Buddha?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Presale spots are available and will be closed off at 888.
                      To qualify, partake in community contests through Discord,
                      Twitter, Instagram and win or participate in the Public
                      Sale.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem data-aos="fade-up" data-aos-duration="2000">
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      How many Lucky Buddha NFTs can I mint?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      888 Lucky Buddhas and Mandalas will be held by the team as
                      part of their reserves, for community rewards and event
                      activations.
                    </p>
                    <p>
                      Exclusive members of the presale will each be able to mint
                      a maximum amount of 2 Lucky Buddhas during the presale.
                      The presale will be limited to 888 members and there will
                      be a maximum amount of 888 Lucky Buddhas and Mandalas
                      available to be minted during the presale.
                    </p>
                    <p>
                      During our public launch, the remaining Lucky Buddhas will
                      be minted. Each transaction will be limited to 8 Lucky
                      Buddhas and Mandalas in order to maintain lower gas fees
                      and to give our community a fair opportunity to own a
                      Lucky Buddha or Mandala.
                    </p>
                    <p>Per wallet limit of LuckyBuddha NFTs will be 88.</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem data-aos="fade-up" data-aos-duration="2000">
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      What is the secondary market structure for Lucky Buddha
                      NFT?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The amount of royalties is fixed at 10% to finance the
                      LuckyBuddha roadmap. We have the ambition to organize
                      multiple events around the world in order to strengthen
                      the community and build a network of entrepreneurs and
                      investors with the same mindset and common interests. The
                      funds collected will benefit the holders. This percentage
                      can decrease over time.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem data-aos="fade-up" data-aos-duration="2000">
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      How do I join the LuckyBuddha NFT community?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Follow us on{" "}
                      <a
                        href="https://twitter.com/buddha_nft"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Twitter
                      </a>
                      &nbsp; &amp; join our{" "}
                      <a
                        href="https://discord.gg/vwnDGbZn8m"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Discord
                      </a>
                      &nbsp; to stay updated!{" "}
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="footer mt-5 mb-5">
        <Container>
          <div className="d-flex justify-content-center">
            <div className="">
              <img
                className="img-fluid"
                src={require("../../assets/images/logo.png").default}
                alt=""
              />
            </div>
          </div>
          <div className="text-right">
            <Link
              to="/terms"
              target="_blank"
              rel="noreferrer"
              className="footer-text text-pri "
            >
              Terms of service
            </Link>
            <p>
              <span className="font-weight-bold">
                © {new Date().getFullYear()} Lucky Buddha NFT | Original. All
                rights reserved.
              </span>
            </p>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Home;
